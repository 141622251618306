






























































































import { Vue, Component } from 'vue-property-decorator'
import reportService, { FlexDividend, FlexTrade } from '@/services/reportService'
import utility from '@/common/utility'
import { Sort } from '@/services/data'
import SearchFilter from '@/components/search-filter.vue'
import SortHeader from '@/components/SortHeader.vue'
import UpDownBar from '@/views/Report/UpDownBar.vue'

let pageTrades: FlexTrade[] = []

@Component({
  components: { UpDownBar, SortHeader, SearchFilter }
})
export default class Trades extends Vue {
  trades: FlexTrade[] = []
  selectedSymbols = new Array<string>()
  selectedOptTypes = new Array<string>()
  symbols: string[] = []
  lastSyncDate = ''
  loading = false

  async mounted () {
    await this.loadData(false)
    this.lastSyncDate = await reportService.getLastSyncDate()
  }

  refresh (force: boolean) {
    this.loadData(force)
  }

  async loadData (forceUpdate: boolean) {
    this.loading = true
    const data = await reportService.getTrades('Option', forceUpdate, true)
    this.loading = false
    if (data.Error) {
      await this.$alert(data.Error)
      return
    }
    pageTrades = data.Result
    this.symbols = utility.getDistinct(data.Result.map(p => utility.getSymbolDisplay(p.SymbolName, p.Currency)))
    this.trades = utility.freezeArrayItems(pageTrades)
    this.lastSyncDate = await reportService.getLastSyncDate()
  }

  sortSymbol (direction: 'Asc' | 'Desc') {
    utility.sortByFiled(this.trades, direction, p => p.SymbolName)
  }

  sortRight (direction: 'Asc' | 'Desc') {
    utility.sortByFiled(this.trades, direction, p => p.Right)
  }

  private filterSymbols () {
    let filter = pageTrades.filter(p => this.selectedSymbols.length === 0 || this.selectedSymbols.find(q => utility.iSameSymbol(q, p.SymbolName, p.Currency)))
    filter = filter.filter(p => this.selectedOptTypes.length === 0 || this.selectedOptTypes.find(q => q.startsWith(p.Right)))
    this.trades = utility.freezeArrayItems(filter)
  }
}
